import React, { useState } from "react";
import { emailBox, emailInput, emailSubmit } from "./mailingList.module.css";

interface IProps {
  isOnLightBackground: boolean;
}

const MailingList: React.FC<IProps> = () => {
  const [email, setEmail] = useState("");

  return (
    <div className={emailBox}>
      <p>Newsletter</p>
      <form
        action="https://rckive.us5.list-manage.com/subscribe/post?u=88346b40388adbfe4b5848a7f&amp;id=8fecbc5653"
        method="post"
        target="_blank"
        noValidate
      >
        <input
          type="text"
          name="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="your email address"
          className={emailInput}
        />
        <div
          style={{ left: "-5000px", position: "absolute" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_88346b40388adbfe4b5848a7f_8fecbc5653"
            tabIndex={-1}
            value=""
          />
        </div>

        <button name="subscribe" className={emailSubmit} type="submit">
          send
        </button>
      </form>
    </div>
  );
};

export default MailingList;
