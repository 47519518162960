import { Link } from "gatsby";
import React from "react";
import {
  content,
  container,
  headerLink,
  header,
  inner,
  footer,
  innerLong,
  footerInner,
  headerLeft,
  headerRight,
  gallery,
  footerBlock,
  footerEnd,
  copyrightSymbol,
  attribution,
} from "./layout.module.css";
import "./layout.css";
import { Helmet } from "react-helmet";
import MailingList from "./mailingList";
// import gallery1 from "../assets/gallery-1.png";

interface IProps {
  hasLongInner?: boolean;
}

const Layout: React.FC<IProps> = ({ children, hasLongInner = false }) => {
  return (
    <>
      <Helmet>
        <title>RCKIVE</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="description" content="How a new generation does photos" />
        <meta name="keywords" content="photos,printing,box" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="EN" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Helmet>

      <div className={container}>
        <div className={header}>
          <div className={headerLeft}>
            <Link to={"/"} className={headerLink}>
              <div>
                <h1>RCKIVE</h1>
              </div>
            </Link>
          </div>
          <div className={headerRight}>
            The Netherlands only - more countries soon - stay tuned
          </div>
        </div>

        <div className={content}>
          <div className={`${inner} ${hasLongInner ? innerLong : ""}`}>
            {children}
          </div>
          <div className={gallery}></div>
        </div>

        <div className={footer}>
          <div className={footerInner}>
            <div className={footerBlock}>
              <p>help@rckive.com</p>
              <p>@rckive_official</p>
            </div>
            <div className={footerBlock}>
              <MailingList isOnLightBackground={hasLongInner} />
            </div>
            <div className={footerBlock}>
              <a href="https://instagram.com/rckive_official/">Instagram</a>
              <a href="https://twitter.com/RCKIVE_official">Twitter</a>
            </div>
            <div className={footerBlock}>
              <Link to={"/terms"}>Terms</Link>
            </div>
          </div>
          <div className={footerEnd}>
            <div>
              <span className={attribution}>
                Google Play and the Google Play logo are trademarks of Google
                LLC.
              </span>
              <span className={copyrightSymbol}>©</span> RCKIVE 2022
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
